.intro {
  padding: 12rem 0 0;
  background-color: #fff;
  background-size: 104vw;
  background-image: url("../images/intro-bg1.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
}
@media (max-width: 767px) {
  .intro {
    overflow: hidden;
  }
}
.intro::after {
  content: "";
  width: 50rem;
  height: 82.4rem;
  background-image: url("../images/intro-bg2.jpg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100%;
  left: 0;
  bottom: 0;
}
.intro__inner {
  max-width: 104rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}
.intro__visual {
  margin-bottom: 10rem;
  position: relative;
}
@media (max-width: 767px) {
  .intro__visual {
    margin-bottom: 6.7rem;
  }
  .intro__visual > img {
    height: 28rem;
    object-fit: cover;
  }
}
.intro__title {
  width: 17.6rem;
  position: absolute;
  right: 4.8rem;
  top: -6rem;
}
@media (max-width: 767px) {
  .intro__title {
    width: 9rem;
    right: 2rem;
  }
}
.intro__visual-ac {
  position: absolute;
}
.intro__visual-ac--1 {
  width: 12rem;
  left: -6rem;
  top: -4.8rem;
}
@media (max-width: 767px) {
  .intro__visual-ac--1 {
    width: 5.6rem;
    left: 0.4rem;
    top: -3.2rem;
  }
}
.intro__visual-ac--2 {
  width: 14.2rem;
  bottom: -1.2rem;
  right: -7.8rem;
}
@media (max-width: 767px) {
  .intro__visual-ac--2 {
    width: 11.2rem;
    right: 0;
    bottom: -1.7rem;
  }
}
.intro__visual-ac--3 {
  width: 17rem;
  left: -2rem;
  bottom: -2.5rem;
}
@media (max-width: 767px) {
  .intro__visual-ac--3 {
    width: 11.7rem;
    bottom: -1.2rem;
  }
}
.intro__row {
  display: grid;
  grid-template-columns: 37.6rem 1fr;
  gap: 14.4rem;
  margin-bottom: 5rem;
}
@media (max-width: 767px) {
  .intro__row {
    display: block;
    margin-bottom: 4rem;
  }
}
@media (max-width: 767px) {
  .intro__lead {
    width: 28.2rem;
    margin-left: 2rem;
    margin-bottom: 3.2rem;
  }
  .intro__lead-bg {
    margin-left: -1rem;
  }
}
@media (max-width: 767px) {
  .intro__desc {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 260%; /* 36.4px */
    padding: 0 2rem;
  }
}
.intro__gallery {
  position: relative;
  z-index: 2;
}

.model-course {
  padding: 10rem 0 0;
}
@media (max-width: 767px) {
  .model-course {
    padding: 7rem 0 0;
  }
}
.model-course__title {
  margin-bottom: 8.4rem;
  width: 54.5rem;
}
@media (max-width: 767px) {
  .model-course__title {
    width: 18.5rem;
    margin-bottom: 4rem;
  }
}

.model-course-items {
  max-width: 130rem;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .model-course-items {
    padding: 0 2rem;
  }
}

.model-course-item {
  display: grid;
  grid-template-columns: 68rem 1fr;
  align-items: end;
  gap: 8rem;
  background-image: url("../images/model-course/MODEL COUSE.01_bg.svg");
  background-repeat: no-repeat;
  background-position: right top;
  margin-bottom: 6rem;
}
@media (max-width: 767px) {
  .model-course-item {
    display: block;
    margin-bottom: 4.8rem;
    background-position: right top 26rem;
    background-size: 8.1rem;
  }
}
.model-course-item--2 {
  background-image: url("../images/model-course/MODEL COUSE.02_bg.svg");
}
.model-course-item--3 {
  background-image: url("../images/model-course/MODEL COUSE.03_bg.svg");
}
.model-course-item:last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 768px) {
  .model-course-item__body {
    padding-bottom: 2rem;
  }
}
.model-course-item__image {
  padding: 2rem;
  position: relative;
}
@media (max-width: 767px) {
  .model-course-item__image {
    padding: 1rem;
    margin-bottom: 2.4rem;
  }
}
.model-course-item__image img {
  border: 1px solid #2c2c2c;
}
.model-course-item__image::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 6rem;
  height: 6rem;
  border-top: 1px solid #2c2c2c;
  border-right: 1px solid #2c2c2c;
}
@media (max-width: 767px) {
  .model-course-item__image::before {
    width: 4rem;
    height: 4rem;
  }
}
.model-course-item__image::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 6rem;
  height: 6rem;
  border-bottom: 1px solid #2c2c2c;
  border-left: 1px solid #2c2c2c;
}
@media (max-width: 767px) {
  .model-course-item__image::after {
    width: 4rem;
    height: 4rem;
  }
}
.model-course-item__no {
  margin-bottom: 2.4rem;
}
@media (max-width: 767px) {
  .model-course-item__no {
    margin-bottom: 1.6rem;
  }
}
.model-course-item__title {
  margin-bottom: 2.4rem;
}
@media (max-width: 767px) {
  .model-course-item__title {
    font-size: 2rem;
    margin-bottom: 1.6rem;
  }
}
.model-course-item__desc {
  margin-bottom: 3.2rem;
}
@media (max-width: 767px) {
  .model-course-item__desc {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
}

.lity {
  z-index: 39990 !important;
}

.movie {
  padding: 12.2rem 0 15rem;
  position: relative;
}
@media (max-width: 767px) {
  .movie {
    overflow: hidden;
    padding: 7.2rem 0 9.6rem;
  }
}
.movie__inner {
  display: flex;
  gap: 12rem;
  justify-content: center;
}
@media (max-width: 767px) {
  .movie__inner {
    display: block;
    padding: 0 2rem;
  }
}
.movie__image {
  display: block;
}
.movie__body {
  width: 42rem;
}
@media (max-width: 767px) {
  .movie__body {
    width: 100%;
  }
}
.movie__title {
  margin-bottom: 4.8rem;
  margin-left: 0;
  width: 22.5rem;
}
@media (max-width: 767px) {
  .movie__title {
    margin: 0 auto 2.4rem;
    width: 15.7rem;
  }
}
.movie__lead {
  margin-bottom: 4.8rem;
}
@media (max-width: 767px) {
  .movie__lead {
    text-align: center;
    font-size: 2.4rem;
    margin: 0 auto 2rem;
  }
}
@media (max-width: 767px) {
  .movie__desc {
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 5.2rem;
  }
}
.movie__image {
  width: 44rem;
  padding: 2rem;
  position: relative;
}
@media (max-width: 767px) {
  .movie__image {
    margin: auto;
    width: 26rem;
    padding: 1.7rem;
  }
}
.movie__image > img {
  border: 1px solid #2c2c2c;
}
.movie__image::after, .movie__image::before {
  content: "";
  width: 8rem;
  height: 8rem;
  position: absolute;
}
@media (max-width: 767px) {
  .movie__image::after, .movie__image::before {
    width: 4.8rem;
    height: 4.8rem;
  }
}
.movie__image::before {
  left: 0;
  top: 0;
  border-left: 1px solid #2c2c2c;
  border-top: 1px solid #2c2c2c;
}
.movie__image::after {
  right: 0;
  bottom: 0;
  border-right: 1px solid #2c2c2c;
  border-bottom: 1px solid #2c2c2c;
}
.movie__image-ac {
  position: absolute;
}
.movie__image-ac--1 {
  left: -8.95rem;
  bottom: -2.8rem;
  width: 15.8rem;
}
@media (max-width: 767px) {
  .movie__image-ac--1 {
    width: 9.6rem;
    left: -4.2rem;
    bottom: -4.6rem;
  }
}
.movie__image-ac--2 {
  width: 7rem;
  top: -4rem;
  right: -4.5rem;
}
.movie__image-play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 7.2rem;
}
.movie__image-usage {
  position: absolute;
  right: -0.6rem;
  bottom: 9rem;
}
@media (max-width: 767px) {
  .movie__image-usage {
    bottom: 6rem;
  }
}
.movie__bg {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 10rem;
}
@media (max-width: 767px) {
  .movie__bg {
    max-width: none;
    width: 125.3rem;
    bottom: 11rem;
    left: -43rem;
  }
}

.merit {
  position: relative;
  padding: 12.5rem 0 6rem;
  overflow: hidden;
}
@media (max-width: 767px) {
  .merit {
    padding: 7.2rem 0 4rem;
  }
}
.merit__inner {
  max-width: 112rem;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .merit__inner {
    padding: 0 2rem;
  }
}
.merit__title {
  margin-bottom: 6.4rem;
  width: 27.6rem;
}
@media (max-width: 767px) {
  .merit__title {
    width: 14.3rem;
    margin-bottom: 4rem;
  }
}

@media (max-width: 767px) {
  .merit-items {
    padding-bottom: 4rem;
  }
}

.merit-item {
  display: grid;
  grid-template-columns: 48rem 56rem;
  grid-template-areas: "body image";
  gap: 8rem;
  margin-bottom: 8rem;
  position: relative;
  z-index: 2;
}
@media (max-width: 767px) {
  .merit-item {
    display: flex;
    flex-direction: column-reverse;
    gap: 2.6rem;
    margin-bottom: 4rem;
  }
}
.merit-item:last-child {
  margin-bottom: 0;
}
.merit-item:nth-child(1) .merit-item__image::after {
  background-image: url("../images/merit/merit-bg1.jpg");
  right: -16rem;
  top: 0;
}
.merit-item:nth-child(2) .merit-item__image::after {
  background-image: url("../images/merit/merit-bg2.jpg");
  left: -16rem;
  top: 0;
}
.merit-item:nth-child(3) .merit-item__image::after {
  background-image: url("../images/merit/merit-bg3.jpg");
  right: -16rem;
  top: 0;
}
.merit-item:nth-child(even) {
  grid-template-columns: 56rem 48rem;
  grid-template-areas: "image body";
}
.merit-item__body {
  grid-area: body;
  position: relative;
  z-index: 2;
}
.merit-item__image {
  grid-area: image;
  position: relative;
}
.merit-item__image::after {
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 38.3rem;
  height: 45.4rem;
}
.merit-item__image img {
  position: relative;
  z-index: 1;
  border: solid 1px #2c2c2c;
}
.merit-item__title {
  margin: 4.8rem 0 4rem;
}
@media (max-width: 767px) {
  .merit-item__title {
    margin: -6.7rem 0 2rem 8.8rem;
    font-size: 2rem;
  }
}
@media (max-width: 767px) {
  .merit-item__desc {
    font-size: 1.4rem;
  }
}

.user-voice {
  position: relative;
  padding: 9.6rem 0 12rem;
}
@media (max-width: 767px) {
  .user-voice {
    padding: 7.2rem 0 2.4rem;
  }
}
.user-voice__title {
  margin-bottom: 6.4rem;
  width: 44.9rem;
}
@media (max-width: 767px) {
  .user-voice__title {
    width: 30rem;
    margin-bottom: 4rem;
  }
}

.user-voice-items {
  max-width: 128rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 40rem);
  gap: 4rem;
}
@media (max-width: 767px) {
  .user-voice-items {
    display: block;
    padding: 0 2rem;
    margin-bottom: 5.6rem;
  }
}

.user-voice-item {
  border-top: 1px solid #2c2c2c;
  padding: 3.2rem 0 0;
}
@media (max-width: 767px) {
  .user-voice-item {
    margin-bottom: 3.2rem;
  }
  .user-voice-item:last-child {
    margin-bottom: 0;
  }
}
.user-voice-item__header {
  display: grid;
  grid-template-columns: 1fr 13.7rem;
  gap: 1.2rem;
  padding: 0.8rem 0 0;
  margin-bottom: 2.4rem;
}
@media (max-width: 767px) {
  .user-voice-item__header {
    grid-template-columns: 1fr 10rem;
    padding: 0;
    margin-bottom: 1.6rem;
  }
}
.user-voice-item__no {
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .user-voice-item__no {
    margin-bottom: 1rem;
    padding-top: 0.8rem;
  }
}
.user-voice-item__title {
  font-size: 2rem;
  font-weight: 500;
  line-height: 175%; /* 35px */
}
@media (max-width: 767px) {
  .user-voice-item__title {
    font-size: 1.8rem;
  }
}
.user-voice-item__image {
  padding: 0.4rem;
  border: solid 1px #2c2c2c;
  border-radius: 100%;
  overflow: hidden;
}
.user-voice-item__image img {
  border-radius: 100%;
}
.user-voice-item__body {
  margin-bottom: 1.6rem;
  padding: 0 0 2.4rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22402%22%20height%3D%222%22%20viewBox%3D%220%200%20402%202%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M1%201H401%22%20stroke%3D%22%232C2C2C%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-dasharray%3D%220.01%2010%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: repeat-x;
  background-position: center bottom;
}
@media (max-width: 767px) {
  .user-voice-item__body {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  .user-voice-item__desc {
    font-size: 1.4rem;
  }
}
.user-voice-item__footer {
  text-align: right;
}

.instagram__title {
  max-width: 128rem;
  margin: 0 auto 4.2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  position: relative;
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 200%; /* 64px */
}
@media (max-width: 767px) {
  .instagram__title {
    padding: 0 2rem;
    font-size: 2rem;
    white-space: nowrap;
    margin-bottom: 2.4rem;
  }
}
.instagram__title-ac {
  position: absolute;
  left: 1.5rem;
  top: -4rem;
}
@media (max-width: 767px) {
  .instagram__title-ac {
    left: 2.6rem;
    top: -3.3rem;
    width: 18rem;
  }
}
.instagram__tags {
  gap: 0.8rem;
  display: flex;
}
.instagram__tag {
  border: 1px solid #2c2c2c;
  background: #e0eef5;
  padding: 0.8rem 1.2rem;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 30px */
}
@media (max-width: 767px) {
  .instagram__tag {
    font-size: 1.4rem;
    padding: 0.6rem 0.8rem;
  }
}

.instagram-gallery-list {
  border-top: solid 1px #2c2c2c;
  padding: 4rem 0;
  overflow: hidden;
}
@media (max-width: 767px) {
  .instagram-gallery-list {
    padding: 2.4rem 0;
  }
}

.instagram-gallery {
  display: flex;
  gap: 2rem;
}
.instagram-gallery + .instagram-gallery {
  margin-top: 2rem;
}
@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes scroll-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
.instagram-gallery__items {
  display: flex;
  gap: 2rem;
  animation: scroll-left 25s infinite linear 0.5s both;
}
.instagram-gallery__items--reverse {
  animation: scroll-right 25s infinite linear 0.5s both;
}
.instagram-gallery__item {
  display: flex;
  gap: 2rem;
  width: 24rem;
  flex: 0 0 24rem;
}
@media (max-width: 767px) {
  .instagram-gallery__item {
    width: 14rem;
    flex: 0 0 14rem;
  }
  .instagram-gallery__item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.media {
  background: rgba(44, 44, 44, 0.04);
  padding: 12rem 0;
}
@media (max-width: 767px) {
  .media {
    padding: 7.2rem 0;
  }
}
.media__inner {
  max-width: 128rem;
  margin-left: auto;
  margin-right: auto;
}
.media__title {
  margin-bottom: 6.4rem;
  width: 22.3rem;
}
@media (max-width: 767px) {
  .media__title {
    width: 15.5rem;
    margin-bottom: 4rem;
  }
}
@media print, screen and (min-width: 768px) {
  .media .splide__arrows {
    display: none;
  }
}
@media (max-width: 767px) {
  .media .splide__arrows {
    transform: none;
    margin-right: 2rem;
    margin-top: 2.4rem;
  }
}

@media print, screen and (min-width: 768px) {
  .media-items {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    gap: 4rem;
  }
}

.media-item__image {
  margin-bottom: 0.8rem;
}
.media-item__image img {
  height: 19rem;
  object-fit: contain;
}
.media-item__meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.2rem;
}
.media-item__date {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 185%; /* 25.9px */
}
@media (max-width: 767px) {
  .media-item__date {
    font-size: 1.4rem;
  }
}
.media-item__category {
  color: #0e90cd;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 125%; /* 15px */
}
.media-item__title {
  font-weight: 500;
  margin-bottom: 1.2rem;
}
@media (max-width: 767px) {
  .media-item__title {
    font-size: 1.6rem;
    margin-bottom: 0.6rem;
  }
}
@media (max-width: 767px) {
  .media-item__desc {
    font-size: 1.4rem;
  }
}

.faq {
  padding: 12rem 0;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .faq {
    overflow: hidden;
    padding: 7.2rem 0;
  }
}
.faq__inner {
  max-width: 96rem;
  margin: auto;
}
@media (max-width: 767px) {
  .faq__inner {
    position: relative;
    padding: 0 2rem;
  }
}
.faq__bg {
  position: absolute;
}
.faq__bg--1 {
  width: 11.6rem;
  left: 6rem;
  top: 14.2rem;
}
@media (max-width: 767px) {
  .faq__bg--1 {
    z-index: 1;
    width: 7.1rem;
    left: 2rem;
    top: 7rem;
  }
}
.faq__bg--2 {
  width: 40rem;
  right: 0;
  top: 0;
}
@media (max-width: 767px) {
  .faq__bg--2 {
    width: 20rem;
  }
}
.faq__bg--3 {
  width: 22rem;
  top: 55.2rem;
  left: 0;
}
@media (max-width: 767px) {
  .faq__bg--3 {
    top: auto;
    bottom: -8rem;
    width: 16rem;
  }
}
.faq__bg--4 {
  right: 6rem;
  bottom: 22rem;
  width: 8.5rem;
}
@media (max-width: 767px) {
  .faq__bg--4 {
    display: none;
  }
}
.faq__title {
  margin-bottom: 6.4rem;
}
@media (max-width: 767px) {
  .faq__title {
    margin-bottom: 4rem;
    width: 14.3rem;
  }
}

.faq-items {
  position: relative;
  z-index: 2;
  border-top: 1px solid #2C2C2C;
}

.faq-item {
  padding: 2.8rem 0;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22962%22%20height%3D%222%22%20viewBox%3D%220%200%20962%202%22%20fill%3D%22none%22%3E%20%3Cpath%20d%3D%22M1%201L961%200.999916%22%20stroke%3D%22%232C2C2C%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-dasharray%3D%220.01%206%22%2F%3E%3C%2Fsvg%3E");
  background-position: center bottom;
  background-repeat: repeat-x;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
@media (max-width: 767px) {
  .faq-item {
    padding: 2.4rem 0;
  }
}
.faq-item__title {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .faq-item__title {
    font-size: 1.5rem;
    align-items: normal;
  }
}
.faq-item__title::before {
  content: "";
  display: block;
  width: 4rem;
  height: 4rem;
  background-image: url("../images/faq/Q.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: 1px #2c2c2c solid;
}
@media (max-width: 767px) {
  .faq-item__title::before {
    width: 2.8rem;
    height: 2.8rem;
  }
}
.faq-item__title.is-active {
  color: #1091bf;
}
.faq-item__title span {
  margin-left: 3.2rem;
}
@media (max-width: 767px) {
  .faq-item__title span {
    margin-left: 1.2rem;
  }
}
.faq-item__title svg {
  margin-left: auto;
  width: 4rem;
  height: 4rem;
}
@media (max-width: 767px) {
  .faq-item__title svg {
    width: 2.8rem;
    height: 2.8rem;
  }
}
.faq-item__desc {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 185%; /* 25.9px */
}
.faq-item__desc p {
  padding-left: 7.2rem;
  padding-top: 1.6rem;
}
@media (max-width: 767px) {
  .faq-item__desc p {
    padding-left: 3.7rem;
    padding-top: 0.8rem;
  }
}

.price {
  background: rgba(44, 44, 44, 0.04);
  padding: 12rem 0;
}
@media (max-width: 767px) {
  .price {
    padding: 7.2rem 0;
  }
}
.price__inner {
  max-width: 128rem;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .price__inner {
    padding: 0 2rem;
  }
}
.price__title {
  margin-bottom: 6.4rem;
  width: 18.7rem;
}
@media (max-width: 767px) {
  .price__title {
    width: 12.8rem;
    margin-bottom: 4rem;
  }
}

.price-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
}
@media (max-width: 767px) {
  .price-items {
    display: block;
  }
}

.price-item {
  background-color: #fff;
  padding: 1rem;
  --price-color: #2c2c2c;
  position: relative;
}
@media (max-width: 767px) {
  .price-item {
    padding: 1.2rem;
    margin-bottom: 2rem;
  }
  .price-item:last-child {
    margin-bottom: 0;
  }
}
.price-item--special {
  --price-color: #1091bf;
}
.price-item--special .price-item__label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%229%22%20viewBox%3D%220%200%2012%209%22%20fill%3D%22none%22%3E%20%3Cpath%20d%3D%22M6.00195%209L12.002%201.90735e-06L0.00195391%208.58275e-07L6.00195%209Z%22%20fill%3D%22%230E90CD%22%2F%3E%3C%2Fsvg%3E");
}
.price-item__flag {
  position: absolute;
  right: 0;
  top: 0;
}
.price-item__inner {
  border: 1px var(--price-color) solid;
  padding: 3rem 3rem 4.8rem;
  height: 100%;
}
@media (max-width: 767px) {
  .price-item__inner {
    padding: 3.2rem 2.4rem;
  }
}
.price-item__label {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.35;
  background: var(--price-color);
  width: fit-content;
  margin: 0 auto 2rem;
  color: #fff;
  padding: 0.8rem 1.6rem 1rem;
  position: relative;
}
.price-item__label::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 1px;
  width: 1.2rem;
  height: 0.9rem;
  transform: translate(-50%, 100%);
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%229%22%20viewBox%3D%220%200%2012%209%22%20fill%3D%22none%22%3E%20%3Cpath%20d%3D%22M6.00195%209L12.002%201.90735e-06L0.00195391%208.58275e-07L6.00195%209Z%22%20fill%3D%22%232C2C2C%22%2F%3E%3C%2Fsvg%3E");
}
.price-item__title {
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 160%; /* 51.2px */
  text-align: center;
  margin: 0 0 2.4rem;
}
@media (max-width: 767px) {
  .price-item__title {
    font-size: 2.8rem;
    margin-bottom: 1.4rem;
  }
}
.price-item__badge {
  margin: 0 auto 2.4rem;
  display: block;
  width: fit-content;
  padding: 0.6rem 1.6rem 0.8rem 1.6rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 90px;
  font-weight: 500;
  border: 1px solid #2c2c2c;
}
@media (max-width: 767px) {
  .price-item__badge {
    font-size: 1.2rem;
  }
}
.price-item__desc {
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.85;
  margin-bottom: 3.2rem;
}
@media (max-width: 767px) {
  .price-item__desc {
    font-size: 1.3rem;
    margin-bottom: 2.4rem;
  }
}
.price-item__line {
  display: flex;
  padding: 0 0 1.2rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22322%22%20height%3D%222%22%20viewBox%3D%220%200%20322%202%22%20fill%3D%22none%22%3E%20%3Cpath%20d%3D%22M1%201L321%200.999972%22%20stroke%3D%22%232C2C2C%22%20stroke-opacity%3D%220.2%22%20stroke-width%3D%222%22%20stroke-miterlimit%3D%2216%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-dasharray%3D%220.01%206%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: repeat-x;
  background-position: center bottom;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;
}
@media (max-width: 767px) {
  .price-item__line {
    padding-bottom: 1rem;
    margin-bottom: 1.6rem;
  }
}
.price-item__line:last-child {
  margin-bottom: 0;
}
.price-item__line-name {
  font-weight: 500;
}
.price-item__line-price {
  font-size: 3.2rem;
  font-weight: bold;
}
.price-item__line-price span {
  font-size: 1.6rem;
  margin-left: 0.6rem;
}
.price-item__memo {
  font-size: 1.3rem;
  margin-top: 3rem;
  text-align: center;
  font-weight: 400;
  line-height: 1.5;
}
@media (max-width: 767px) {
  .price-item__memo {
    margin-top: 2rem;
  }
}

.access {
  padding: 12rem 0 37.6rem;
  position: relative;
}
@media (max-width: 767px) {
  .access {
    overflow: hidden;
    padding: 5.2rem 0 21.2rem;
  }
}
.access__inner {
  max-width: 128rem;
  margin: 0 auto;
}
.access__row {
  display: grid;
  grid-template-columns: 56rem 64rem;
  justify-content: space-between;
  margin: 0 auto 8rem;
}
@media (max-width: 767px) {
  .access__row {
    display: block;
    margin-bottom: 4.8rem;
  }
}
.access__title {
  margin-bottom: 4rem;
  width: 25rem;
}
@media (max-width: 767px) {
  .access__title {
    width: 16.7rem;
  }
}
.access__image {
  height: 42.8rem;
  border: solid 1px #2c2c2c;
}
@media (max-width: 767px) {
  .access__image {
    width: 100%;
    height: 25.8rem;
  }
}
.access__image iframe {
  width: 100%;
  height: 100%;
}
.access__table {
  border-top: 1px solid #2c2c2c;
  padding: 1.6rem 0 0;
}
@media (max-width: 767px) {
  .access__table {
    font-size: 1.4rem;
    margin: 0 2rem;
    margin-bottom: 4rem;
  }
}
.access__table dl {
  padding: 1.6rem 0;
  display: grid;
  grid-template-columns: 12rem 1fr;
  gap: 1rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22562%22%20height%3D%222%22%20viewBox%3D%220%200%20562%202%22%20fill%3D%22none%22%3E%20%3Cpath%20d%3D%22M1%201L561%200.999951%22%20stroke%3D%22%232C2C2C%22%20stroke-opacity%3D%220.2%22%20stroke-width%3D%222%22%20stroke-miterlimit%3D%2216%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-dasharray%3D%220.01%206%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: repeat-x;
  background-position: center bottom;
}
@media (max-width: 767px) {
  .access__table dl {
    grid-template-columns: 7rem 1fr;
    gap: 1.6rem;
    padding: 1.8rem 0;
  }
}
.access__table dt {
  color: #1091bf;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.85;
}
@media (max-width: 767px) {
  .access__table dt {
    font-size: 1.4rem;
  }
}
.access__table dd {
  font-weight: 400;
  line-height: 1.85;
}
.access__slider {
  border: 1px solid #2c2c2c;
  background: #fff;
  padding: 4.8rem;
}
@media (max-width: 767px) {
  .access__slider {
    margin: 2rem;
    padding: 2.4rem 1.6rem;
  }
}
.access__slider-title {
  border-bottom: 1px solid #2c2c2c;
  font-size: 2rem;
  font-weight: 500;
  line-height: 150%; /* 30px */
  padding: 0 0 2rem;
  margin-bottom: 4rem;
}
@media (max-width: 767px) {
  .access__slider-title {
    font-size: 1.6rem;
    padding: 0 0 1.2rem;
    margin-bottom: 2rem;
  }
}
.access__bg {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 4rem;
}
@media (max-width: 767px) {
  .access__bg {
    max-width: none;
    width: 125.3rem;
    bottom: 0;
    left: -43rem;
  }
}

.splide__pagination {
  display: none;
}

.splide__arrows {
  position: absolute;
  bottom: 0.8rem;
  right: 0;
  display: grid;
  grid-template-columns: repeat(2, 6.4rem);
  gap: 1.6rem;
  height: 6.4rem;
  transform: translateY(50%);
}
@media (max-width: 767px) {
  .splide__arrows {
    position: relative;
    bottom: auto;
    right: auto;
    grid-template-columns: repeat(2, 5.6rem);
    margin-left: auto;
    width: fit-content;
    margin-right: 2rem;
    height: 5.6rem;
    margin: 1.6rem 0 0 auto;
    transform: none;
  }
}

.splide__arrow {
  position: relative;
  top: auto;
  transform: none;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2264%22%20height%3D%2264%22%20viewBox%3D%220%200%2064%2064%22%20fill%3D%22none%22%3E%20%3Ccircle%20cx%3D%2232%22%20cy%3D%2232%22%20r%3D%2228%22%20fill%3D%22%232C2C2C%22%2F%3E%20%3Ccircle%20cx%3D%2232%22%20cy%3D%2232%22%20r%3D%2231.5%22%20stroke%3D%22%232C2C2C%22%2F%3E%20%3Cpath%20d%3D%22M29.9923%2036.8487L34.841%2032L29.9923%2027.1513%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  opacity: 1;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: contain;
}
@media (max-width: 767px) {
  .splide__arrow {
    border: 0.25px solid #000;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2256%22%20height%3D%2256%22%20viewBox%3D%220%200%2056%2056%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cellipse%20cx%3D%2227.999%22%20cy%3D%2228%22%20rx%3D%2224.5%22%20ry%3D%2224.5%22%20fill%3D%22%232C2C2C%22%2F%3E%3Cpath%20d%3D%22M55.4991%2028C55.4991%2043.1878%2043.1869%2055.5%2027.9991%2055.5C12.8112%2055.5%200.499023%2043.1878%200.499023%2028C0.499023%2012.8122%2012.8112%200.5%2027.9991%200.5C43.1869%200.5%2055.4991%2012.8122%2055.4991%2028Z%22%20stroke%3D%22%232C2C2C%22%2F%3E%3Cpath%20d%3D%22M26.2436%2032.2426L30.4863%2028L26.2436%2023.7574%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  }
}
.splide__arrow[disabled] {
  opacity: 0.2;
}

.splide__arrow--prev {
  left: auto;
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: auto;
}

.splide-progress2 {
  background: #d9d9d9;
  max-width: calc(100% - 4rem);
  margin: auto;
}
@media print, screen and (min-width: 768px) {
  .splide-progress2 {
    display: none;
  }
}

.splide-progress2__bar {
  background: #2c2c2c;
  height: 1px;
  transition: width 400ms ease;
  width: 0;
  margin-top: 4rem;
}

.splide-progress {
  background: #d9d9d9;
  max-width: 100.8rem;
}

.splide-progress__bar {
  background: #2c2c2c;
  height: 1px;
  transition: width 400ms ease;
  width: 0;
  margin-top: 5.6rem;
}
@media (max-width: 767px) {
  .splide-progress__bar {
    margin-top: 2.4rem;
  }
}

.access-items {
  gap: 7.2rem;
}

.access-item {
  width: 28rem;
  flex: 0 0 28rem;
}
@media (max-width: 767px) {
  .access-item {
    width: 24rem;
    flex: 0 0 24rem;
  }
}
.access-item p {
  font-size: 1.4rem;
  line-height: 1.85;
}
@media (max-width: 767px) {
  .access-item p {
    font-size: 1.3rem;
  }
}
.access-item__image {
  position: relative;
  margin-bottom: 1.2rem;
}
.access-item__image::after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2248%22%20height%3D%22187%22%20viewBox%3D%220%200%2048%20187%22%20fill%3D%22none%22%3E%20%3Cpath%20d%3D%22M35%2093.5L15%2081L15%20106L35%2093.5Z%22%20fill%3D%22%232C2C2C%22%2F%3E%3C%2Fsvg%3E");
  width: 4.8rem;
  height: 100%;
  right: -1.2rem;
  position: absolute;
  top: 0;
  transform: translate(100%, 0);
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .access-item__image::after {
    right: 0;
    background-size: 3.2rem;
    background-position: center;
    width: 2.8rem;
  }
}
.access-item__image.is-end::after {
  display: none;
}
.access-item__image-badge {
  position: absolute;
  left: 0;
  top: 0;
}
.access-item__image > img {
  border: solid #2c2c2c 1px;
}

.modal-wrapper {
  background: rgba(44, 44, 44, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  overscroll-behavior: contain;
  z-index: 21000;
  visibility: hidden;
  pointer-events: none;
}
.modal-wrapper.visible {
  pointer-events: auto;
  visibility: visible;
}

.modal {
  margin: 9.7rem auto;
  max-width: 120rem;
  background-color: #fff;
  padding: 2rem;
  position: relative;
}
@media (max-width: 767px) {
  .modal {
    margin: 5.7rem 2rem;
    padding: 1rem;
  }
}
.modal__inner {
  border: solid 2px #000;
  padding: 10rem 6rem;
  position: relative;
}
@media (max-width: 767px) {
  .modal__inner {
    border-width: 1px;
    padding: 8.2rem 1.6rem 0;
  }
}
.modal__inner::before {
  content: "";
  width: 100%;
  height: calc(100% - 54.8rem);
  background: #e0eef5;
  position: absolute;
  left: 0;
  bottom: 0;
}
@media (max-width: 767px) {
  .modal__inner::before {
    height: calc(100% - 18.7rem);
  }
}
.modal__no {
  position: absolute;
  top: 10rem;
  left: 6rem;
  width: 12rem;
  z-index: 3;
}
@media (max-width: 767px) {
  .modal__no {
    width: 5.6rem;
    left: 1.6rem;
    top: 2.4rem;
  }
}
.modal__content {
  position: relative;
  z-index: 2;
}
.modal__image {
  padding-top: 10rem;
  max-width: 96rem;
  margin: auto;
}
@media (max-width: 767px) {
  .modal__image {
    padding-top: 0;
    max-width: 100%;
  }
}
.modal__lead {
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 200%; /* 64px */
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  display: inline;
  background-color: #fff;
  position: relative;
  top: -4rem;
  margin-bottom: 4rem;
}
@media (max-width: 767px) {
  .modal__lead {
    font-size: 1.8rem;
    top: -2rem;
  }
}
.modal__desc {
  font-weight: 500;
  margin-bottom: 7rem;
}
@media (max-width: 767px) {
  .modal__desc {
    font-size: 1.4rem;
    margin-bottom: 4.8rem;
  }
}
.modal__box {
  padding: 6rem;
  background-color: #fff;
}
@media (max-width: 767px) {
  .modal__box {
    padding: 4.8rem 1.6rem;
    margin-left: -1.6rem;
    margin-right: -1.6rem;
  }
}
.modal__list {
  display: grid;
  grid-template-columns: 9.6rem 1fr;
  column-gap: 4rem;
  position: relative;
  margin-bottom: 2.4rem;
}
@media (max-width: 767px) {
  .modal__list {
    grid-template-columns: 5.6rem 1fr;
    column-gap: 2rem;
  }
}
.modal__list:last-child {
  margin-bottom: 0;
}
.modal__list:last-child .modal__list-desc {
  background: none;
}
@media (max-width: 767px) {
  .modal__list:last-child .modal__list-desc {
    padding-bottom: 0;
  }
}
.modal__list:last-child::after {
  display: none;
}
.modal__list::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20fill%3D%22none%22%3E%20%3Cpath%20d%3D%22M16.3327%2023.334L24.666%2010.0007L7.99935%2010.0006L16.3327%2023.334Z%22%20fill%3D%22%232C2C2C%22%2F%3E%3C%2Fsvg%3E");
  display: block;
  background-repeat: no-repeat;
  background-position: left 3.2rem bottom;
  width: 100%;
  height: 3.2rem;
  grid-column: span 2;
}
@media (max-width: 767px) {
  .modal__list::after {
    background-position: left 1.3rem bottom;
  }
}
.modal__list-date {
  font-family: "Classico URW";
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 150%; /* 36px */
  margin-bottom: 1.5rem;
  width: fit-content;
  padding: 0.4rem 1.2rem 0.6rem;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-image: url("../images/model-course/date-bg.png");
}
@media (max-width: 767px) {
  .modal__list-date {
    font-size: 1.4rem;
    padding: 0 0.8rem 0.4rem;
    background-size: contain;
    margin-bottom: 0;
  }
}
.modal__list-no {
  padding-top: 0.8rem;
}
.modal__list-title {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0.8rem;
}
@media (max-width: 767px) {
  .modal__list-title {
    font-size: 1.5rem;
  }
}
.modal__list-desc {
  font-size: 1.4rem;
  line-height: 1.85;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%22784%22%20height%3D%222%22%20viewBox%3D%220%200%20784%202%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cline%20x1%3D%221%22%20y1%3D%221%22%20x2%3D%22783%22%20y2%3D%221.00007%22%20stroke%3D%22black%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-dasharray%3D%220.01%206%22%2F%3E%3C%2Fsvg%3E");
  background-position: center bottom;
  background-repeat: repeat-x;
  padding-bottom: 2.4rem;
}
@media (max-width: 767px) {
  .modal__list-desc {
    font-size: 1.3rem;
    padding-bottom: 3.2rem;
  }
}
.modal__footer {
  position: relative;
  z-index: 2;
  background: #2C2C2C;
  padding: 6.4rem 0;
  color: #fff;
}
@media (max-width: 767px) {
  .modal__footer {
    padding: 4rem 0;
  }
}
.modal__button {
  width: 28rem;
  height: 6rem;
  border-radius: 9rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20fill%3D%22none%22%3E%20%3Cpath%20d%3D%22M15.0518%2015.05L24.9512%2024.9495%22%20stroke%3D%22white%22%2F%3E%20%3Cpath%20d%3D%22M24.9482%2015.05L15.0488%2024.9495%22%20stroke%3D%22white%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: center right;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: #fff;
  border: solid 1px #fff;
}
@media (max-width: 767px) {
  .modal__button {
    width: 24rem;
    height: 5.6rem;
  }
}
.modal__close {
  position: absolute;
  right: -3rem;
  top: -3.6rem;
  z-index: 10;
  opacity: 1 !important;
  width: 12rem;
  height: 12rem;
}
@media (max-width: 767px) {
  .modal__close {
    width: 6.4rem;
    height: 6.4rem;
    right: -1rem;
    top: -3rem;
  }
}
.modal__close:hover {
  scale: 0.9;
}

.modal-close-layer {
  background: transparent;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
}

/* animations **/
.intro__visual > img {
  opacity: 0;
  transform: translate(0, 10px);
  transition: 0.8s;
}

.intro__title {
  opacity: 0;
  transform: translate(0, 10px);
  transition: 0.8s;
  transition-delay: 0.4s;
}

.intro__row {
  transition: 0.6s;
  filter: blur(8px);
  transform: translate(0, 10px);
}
.intro__row.is-active {
  transform: translate(0, 0);
  filter: blur(0);
}

.merit-item,
.movie,
.model-course-item {
  opacity: 0;
  transform: translate(0, 10px);
  transition: 0.8s;
}
.merit-item.is-active,
.movie.is-active,
.model-course-item.is-active {
  opacity: 1;
  transform: none;
}

.intro__visual.is-active > img {
  opacity: 1;
  transform: none;
}
.intro__visual.is-active .intro__title {
  opacity: 1;
  transform: none;
}
